import React from "react";
import Image from "next/image";

const Partners = () => {
  const partnerGroup = [
    { id: 1, link: "https://www.nft-dao.org/", logo: "pharaoh.svg" },
    { id: 2, link: "https://www.nft-dao.org/", logo: "dgo-1.svg" },
    { id: 3, link: "https://www.nft-dao.org/", logo: "jade-text.svg" },
    { id: 4, link: "https://www.nft-dao.org/", logo: "esm.svg" },
    { id: 5, link: "https://www.nft-dao.org/", logo: "yvb.svg" },
  ];
  return (
    <>
      <div className="dark:bg-jacarta-900 ">
        <div className="container">
          <div className="grid grid-cols-2 py-8 sm:grid-cols-5">
            {partnerGroup.map((item) => (
              <div
                key={item.id}
                className="flex justify-center"
              >
                <Image
                  width={120}
                  height={103}
                  className="object-contain"
                  src={`/images/partners/${item.logo}`}
                  alt="partner 1"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
