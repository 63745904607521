import Image from "next/image";
import TradeVolume from "./TradeVolume";

const Intro = () => {
    return (
        // <!-- Intro -->
        <section className="bg-gradient-to-r from-[transparent_33%] to-[#F5F8FA_33%] py-36 dark:to-[#101436_33%]">
            <div className="container">
                <div className="lg:flex lg:justify-between">
                    {/* <!-- Image --> */}
                    <div className="relative lg:w-[45%]">
                        <figure className="relative">
                            <Image
                                width={500}
                                height={500}
                                src="/images/home/section-1/ai-chip.jpeg"
                                className="rounded-2.5xl w-full h-full object-cover"
                                alt="web protocol"
                            />
                        </figure>
                    </div>

                    {/* <!-- Info --> */}
                    <div className="py-10 lg:w-[55%] lg:pl-24">
                        <h2 className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
                            AI-Powered Monitoring Ensuring Seamless Online Performance
                        </h2>
                        <p className="dark:text-jacarta-300 mb-8">
                            Monitoring websites with AI offers a proactive approach to maintaining online presence. By
                            periodically capturing screenshots of your website and subjecting them to AI-powered
                            analysis, you gain swift insights into potential issues like outages, font inconsistencies,
                            or image loading problems.
                        </p>
                        <p className="dark:text-jacarta-300">
                            This process provides a real-time pulse on your website&apos;s health,
                            enabling timely intervention and ensuring a seamless user experience. The AI&apos;s trained model
                            swiftly identifies discrepancies, allowing for swift remediation, ultimately enhancing user
                            satisfaction and safeguarding your brand&apos;s online reputation.
                        </p>
                        <TradeVolume/>
                    </div>
                </div>
            </div>
        </section>
        // <!-- end intro -->
    );
};

export default Intro;
