import Image from "next/image";

const Partner1 = () => {
  const partnerItem = [
    "pharaoh.svg",
    "dgo-1.svg",
    "jade-text.svg",
    "esm.svg",
    "yvb.svg",
  ];
  return (
    <>
      {partnerItem.map((item, i) => (
        <div
          className="flex flex-shrink-0 items-center justify-center rounded-2.5xl border border-jacarta-100 bg-white p-6"
          key={i}
        >
          <Image
            width={200}
            height={50}
            className="object-contain"
            src={`/images/partners/${item}`}
            alt="partner item"
          />
        </div>
      ))}
      {partnerItem.map((item, i) => (
        <div
          className="flex flex-shrink-0 items-center justify-center rounded-2.5xl border border-jacarta-100 bg-white p-6"
          key={i}
        >
          <Image
            width={200}
            height={50}
            className="object-contain"
            src={`/images/partners/${item}`}
            alt="partner item"
          />
        </div>
      ))}
    </>
  );
};

export default Partner1;
