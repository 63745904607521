import Faq from "./Faq";
import VideoBlock from "./VideoBlock";

const Participate = () => {
    return (
        <>
            {/* <!-- Video / FAQ --> */}
            <section className="bg-light-base py-24 dark:bg-jacarta-800">
                <div className="container">
                    <div className="mx-auto mb-12 max-w-xl text-center">
                        <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
                            How does it work?
                        </h2>
                        <p className="text-lg dark:text-jacarta-300">
                            Our AI-powered monitoring system operates autonomously, continuously analyzing website
                            elements to swiftly detect and alert about potential issues.
                        </p>
                    </div>
                    <div className="lg:flex lg:flex-nowrap">
                        <div className="lg:w-[59%]">
                            <VideoBlock/>
                        </div>
                        {/* End VideoBlock */}

                        <div className="lg:w-[41%] lg:pl-24">
                            <p className="mb-6 dark:text-jacarta-300">
                                Automated testing and monitoring, driven by AI advancements, stand as pivotal pillars
                                reshaping industries, fundamentally altering how we assure site performance, bolstering
                                efficiency, and amplifying the precision of our digital landscapes.
                            </p>
                            <a
                                href="#"
                                className="mb-8 inline-block text-sm font-bold text-accent"
                            >
                                Learn More
                            </a>
                            <Faq/>
                        </div>
                        {/* End Faq */}
                    </div>
                </div>
            </section>
            {/* <!-- end video / faq -->   */}
        </>
    );
};

export default Participate;
