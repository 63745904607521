/* eslint-disable react/no-unescaped-entities */
import {useState} from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";

function Icon({id, open}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${
                id === open ? "rotate-180" : ""
            } h-4 w-4 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
        </svg>
    );
}

const itemContent = [
    {
        id: 1,
        title: "How do I set up a monitor?",
        descriptions: `To set up a monitor, simply input the URL and validate the AI-generated screenshot—a swift two-step process ensuring comprehensive website monitoring.`,
    },
    {
        id: 2,
        title: "How do notifications work?",
        descriptions: `Simply select what users you want notified per project, then each user can customize how they get notified, e-mail, text or both.`,
    },
    {
        id: 3,
        title: "How much does it cost?",
        descriptions: `Pay for what you use. We only have one pricing for everyone, per project and per addon. The more projects you have you use the more discounts you get. We chose these pricing scheme to have our customers pay the minumum.`,
    },
    {
        id: 4,
        title: "Do you charge per user?",
        descriptions: `No, number of users is unlimited`,
    },
    {
        id: 5,
        title: "How often will my website be tested?",
        descriptions: `Your website is tested every 5 minutes.`,
    },
];

const FaqAccordion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <div className="mb-14">
            {itemContent.map((item) => (
                <Accordion
                    className="accordion-item mb-5 overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600"
                    key={item.id}
                    open={open === item.id}
                    icon={<Icon id={item.id} open={open}/>}
                >
                    <AccordionHeader
                        className="accordion-button collapsed relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-sm text-jacarta-700 dark:bg-jacarta-700 dark:text-white"
                        onClick={() => handleOpen(item.id)}
                    >
                        {item.title}
                    </AccordionHeader>
                    <div className="hidden-style">
                        <AccordionBody
                            className="accordion-body border-t border-jacarta-100 bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-700">
                            {item.descriptions}
                        </AccordionBody>
                    </div>
                </Accordion>
            ))}
        </div>
    );
};

export default FaqAccordion;
